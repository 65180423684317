import { faKey, faHighlighter, faGraduationCap, faFaceGrinBeamSweat } from '@fortawesome/free-solid-svg-icons'

export const summary = [
    {
        id: 1,
        icon: faKey,
        title: 'Key Skills',
        items: [
          'Full-stack development (ASP.NET, .NET Core, C#, React JS, JavaScript, TypeScript)',
          'Cloud platforms (Azure, AWS)',
          'Database management (MS SQL Server), MongoDB',
          'CI/CD and DevOps practices',
        ],
      },
      {
        id: 2,
        icon: faHighlighter,
        title: 'Highlights',
        items: [
          'Currently a Software Developer at Computershare, leading the development of critical .NET Core API resources',
          'Experience in systems engineering and ETL processes',
          'Awarded for performance and innovation, including recognition at CPU\'s Global AI Hackathon',
        ],},
      {
        id: 3,
        icon: faGraduationCap,
        title: 'Education',
        description:
          'Your potential clients will see your services or product on facebook.',
          items: [
            'MSc in Business Information Management (Distinction) from Heriot-Watt University',
            'BEng in Financial and Management Engineering from the University of the Aegean',
          ],},
      {
        id: 4,
        icon: faFaceGrinBeamSweat,
        title: 'Personal',
        description:
          'Your services or product will appear at the top of the Google search.',
          items: [
            'Passionate about continuous learning and tackling coding challenges',
            'Enjoy tennis and rank in the top 2% on CodeWars',
          ],},
]