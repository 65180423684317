export const experience = [
  {
    id: 1,
    title: 'August 2022 - Present',
    company: 'Computershare',
    role: '.NET Developer',
    location: 'Edinburgh, UK',
    description:
      'As a Software Developer at Computershare, I have led the development and delivery of over 60 critical .NET Core API resources, adhering to Clean Architecture principles. I utilized technologies like AutoMapper, MediatR, Kafka, and ElasticSearch to enhance data handling and application flow. My collaboration with the DevOps team resulted in the creation of a generic API template, ensuring efficient delivery and scalability in Azure APIM. I have also trained and mentored junior developers, empowering them to make independent contributions. My achievements include winning an award at CPU’s Global AI Hackathon using the ChatGPT API and being recognized with a performance-based global award for FY23/24 Q4.',
    logo: '/images/experience/cpu.png',
  },
  {
    id: 2,
    title: 'December 2021 - August 2022',
    company: 'Computershare',
    role: 'Junior .NET Developer',
    location: 'Edinburgh, UK',
    description:
      'In my role as a Junior Software Developer at Computershare, I maintained comprehensive documentation to facilitate knowledge sharing and streamline development processes. I collaborated in a DevOps-focused team to optimize software development, testing, and deployment workflows. My responsibilities included developing and maintaining .NET Framework WCF services and gaining expertise in version control, continuous integration, and automated testing within the Azure DevOps workflow. I conducted thorough unit and integration testing with xUnit, achieving 90% code coverage.',
    logo: '/images/experience/cpu.png',
  },
  {
    id: 3,
    title: 'December 2023 - Present',
    company: 'HydroMastery',
    role: 'Freelancer',
    location: 'Remote',
    description:
      'As a freelancer, I developed the HydroMastery application using React JS, Express JS, and Atlas MongoDB, providing personalized workouts and insightful analytics for swimmers and coaches. I initially deployed the application to Azure App Service and later migrated it to AWS Elastic Beanstalk and S3 with CloudFront CDN for enhanced performance and scalability. I established a Miro board and a Jira board to facilitate better collaboration with the client, ensuring efficient project management and communication. My responsibilities included designing and implementing features for various user roles, including coaches, athletes, and administrators, ensuring tailored experiences for each.',
    logo: '/images/logos/GKhub-logo-light.png',
  },
  {
    id: 4,
    title: 'September 2020 - November 2021',
    company: 'Computer Application Services (Workpro)',
    role: 'Systems Engineer',
    location: 'Edinburgh, UK',
    description:
      'As a Systems Engineer at Computer Application Services, I successfully configured and delivered over 20 .NET Core projects for new and existing Workpro customers within established timelines and budget constraints. I designed and maintained ETL processes, utilizing SQL Agent Jobs and stored procedures to seamlessly migrate data. Collaborating with clients and internal teams, I crafted tailored solutions and developed advanced SQL queries and stored procedures/functions to generate high-impact Microsoft SSRS reports.',
    logo: '/images/experience/workpro.png',
  },
  {
    id: 5,
    title: 'September 2019 - September 2020',
    company: 'Computer Application Services (Workpro)',
    role: 'Junior Systems Engineer',
    location: 'Edinburgh, UK',
    description:
      'As a Graduate Systems Engineer, I customized and developed Workpro platform configurations to align with clients’ specific business processes using my SQL expertise. I managed and supported customer systems both on-premise and on the Workpro cloud platform. Additionally, I delivered Workpro demos, training, and walkthroughs to prospects and customers, and created business logic-based views to simplify database complexities for ad hoc reporting.',
    logo: '/images/experience/workpro.png',
  },
  {
    id: 6,
    title: 'February 2021 - September 2021',
    company: 'PeopleCert Education',
    role: '.NET Developer',
    location: 'Remote',
    description:
      'During a rigorous coding bootcamp at PeopleCert Education, I underwent 500 hours of intensive training in software development. The curriculum covered Object-Oriented and Functional Programming, Test-Driven Development, Debugging, Architecture Patterns, Data/Class Modeling, and Version Control (Git). I earned PeopleCert Software Developer Skills Foundation and Advanced C# certifications. My final project was ePet, a full-stack web application developed using the ASP.NET MVC Framework, Entity Framework, and SyncFusion.',
    logo: '/images/experience/peoplecert.png',
  },
  {
    id: 7,
    title: 'June 2018 - August 2019',
    company: 'Heriot-Watt University',
    role: 'Research Assistant',
    location: 'Edinburgh, UK',
    description:
      'As a Research Assistant at Heriot-Watt University, I contributed to a Jaguar Land Rover research project associated with the development of vehicle sensor systems. My work involved designing, operating, and testing data labeling tools using Matlab, which played a significant role in advancing research outcomes.',
    logo: '/images/experience/hwu.png',
  },
  {
    id: 8,
    title: 'September 2018 - September 2019',
    company: 'Heriot-Watt University',
    role: 'Postgraduate Student',
    location: 'Edinburgh, UK',
    description:
      'I completed my MSc in Business Information Management at Heriot-Watt University with Distinction, supported by a funded scholarship. My courses included Big Data Management, Databases and Information Systems, e-Commerce Technologies, and Information Systems Methodologies. For my MSc project, I investigated better sales models via supply chains for SMEs by simulating and optimizing a supply chain network of an SME in Taiwan using the NetLogo framework.',
    logo: '/images/experience/hwu.png',
  },
  {
    id: 9,
    title: 'November 2017 - July 2018',
    company: 'Hellenic Army',
    role: 'Military stuff',
    location: 'Athens, Greece',
    description:
      'During my mandatory military service with the Hellenic Army, I served as administrative staff, where I operated and maintained the timetable system of various services and troubleshot IT systems. I also underwent basic and advanced military training, which honed my discipline and organizational skills.',
    logo: '/images/experience/grarmy.png',
  },
  {
    id: 10,
    title: '2017',
    company: 'Aegean Blue Growth Competition',
    role: 'Founder',
    location: 'National',
    description:
      'I co-founded the startup group "Oceana," aiming to provide information technology innovations within maritime and shipping economics. We won the Aegean Blue Growth Competition with our innovative design of a mobile application to increase the customer experience of cruise ship passengers.',
    logo: '/images/experience/aegean.png',
  },
  {
    id: 11,
    title: 'September 2012 - August 2018',
    company: 'University of the Aegean',
    role: 'Undergraduate Student',
    location: 'Chios, Greece',
    description:
      'I earned a BEng in Financial and Management Engineering (Integrated Masters - 5 years) with a track in Financial Engineering from the University of the Aegean. My coursework included Operations Research, Web Application Development, Computer Networks and Communications, and Business Application of Information Systems. My diploma thesis focused on mobile application design to enhance the experience of cruise ship passengers.',
    logo: '/images/experience/aegean.png',
  },
]